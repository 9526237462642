import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { PortfolioPage, Summary, Review, Aside, Notes } from "../../../components/mdx/portfolio.js";
import { Border } from "../../../components/widgets/container";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PortfolioPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1>{props.pageContext.frontmatter.author} {props.pageContext.frontmatter.title}</h1>
    <Summary mdxType="Summary">
      <Aside mdxType="Aside">
        <p><strong parentName="p">{` Key Facts `}</strong></p>
        <ul>
          <li parentName="ul">{`mean-variance optimization strategy`}</li>
          <li parentName="ul">{`invests in ETFs tracking treasuries, corportate bonds, stocks, and gold`}</li>
          <li parentName="ul">{`rebalances monthly`}</li>
        </ul>
        <p><strong parentName="p">{` Similar Strategies `}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/tt-buoy/"
            }}>{`Buoy`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/tt-rain-or-shine/"
            }}>{`Rain or Shine`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/tt-stocks-on-a-stroll/"
            }}>{`Stocks on a Stroll`}</a></li>
        </ul>
      </Aside>
      <Border mdxType="Border">
        <p>{`This portfolio is `}<strong parentName="p">{`outdated`}</strong>{` and requires improvements to better cope with scenarios where asset prices drop in unison. In the meantime, we suggest using `}<a parentName="p" {...{
            "href": "/portfolios/tt-buoy/"
          }}>{`Buoy`}</a>{` as a replacement.`}</p>
      </Border><br />
      <p><em parentName="p">{`Bonds-NOT`}</em>{` is a proprietary premium strategy by `}<em parentName="p">{`TuringTrader.com`}</em>{`, introduced in October 2020.`}</p>
      <p><em parentName="p">{`Bonds-NOT`}</em>{` aims to continuously beat the aggregate bond market while maintaining a similarly low risk profile. The strategy invests in U.S. Treasuries of various maturities, U.S. Corporate Bonds of various credit rankings, and boosts these returns with investments in U.S. Stocks and Gold.`}</p>
      <p><em parentName="p">{`Bonds-NOT`}</em>{` rebalances monthly, resulting in very low maintenance requirements. These properties make the strategy a great candidate for investing in an uncertain future and having a busy lifestyle.`}</p>
    </Summary>
    <Review mdxType="Review">
      <h2>{`Strategy Rules`}</h2>
      <Aside mdxType="Aside">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "512px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/2b23f891a5d398e021f35a8c1073fc00/01e7c/logo-512x512.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAE3klEQVQ4y2VUWWyUVRS+0iclvhgSeSAQjFAopWWVJeDGYgqiDxIMZakiRkz0wQVZhAiVLcGWgAoFElpppzNTpmWGLpS2086/3HPvv8zSzkwLEqG+woviE/2n95jzUwyJD+ffcu73f+d83zlMSsEcR7J79zKMc14kBDBNM5kQsBQAjgJAFAAMANABIAIA+6WEEsPglMMAoIjyEZFZlmA+WDptM9Pkkzj3k8oBoBsAkIJzjob5vygYJg/ejJszXFswW0LR72nB6upijI2ODj4L9jEAjBMIACjOuScEeBlXFCbCmwikGErKv1KOqEjagrk2FOm6yfwynwHDCbAxzrkSAlQ8Yarz4X51IdyvfgnF1c/BOJ5rjqufGvvGapv6sC9hYsoR69Ku8MunCwMB5ZzzcZNzNDkv0F03uEo7Qp1q6EX25m/I1jYi29CEbGNATXq/Wb28LYxLvmzzot06WoI/7B0wpgIQQ8NkAqDbloCOBWOO5d8VvQ8lJbZ0arh2XxQ/r+3CVz+5huv2RTGeMLF3wPRzpIAx27ZRCFEnpWTMkrCUGLX36Cp6S1fRbl1d79aR/hzp0rD1poaWAPxz2MZFX7Tilup2/CNn+a2htnAAFQyGUNO0R0NDmWlsJGMdbYwlkFU0eWxDk3ruvQAFsk0BfHFLEKduD2N924AvwpTKMG4+0o75tMSEZqDruiocbsGy8iWFUCiMd+6MVLGMK6LhDg2nf9TirfkuqmbuuobzPovg+VA/NsUSVJrPkMp8YXMQP/zxCaCmmwSIuz/do6bPmOXV1JzBfD53jkQxBQAdKtzNWqriYAzX7Y/h/byN1CNS3rUAW7s0ZO8GsPJYB2aTAi3bwYaGqzi3ZIGaXVzqHTr0A+Zy2RABGtQP0+SFpC1U6Z4Ivr036peoG0+MnHYEXmkdQLa+EXee7MRcSiAXEmOxG3j8xCm1bPlq78DBw5jP55sJMDLRYI/UJTuQkqTw08mg5xP1vchWNvgMn/YwlUqqnp5eNb9ssVdTewZHRoZrCHA/AUoBHvXp+Q+C6p0DMRxMiv8AiSGZ+KWtITxw4ZbPPqHpmMlk1Fdf78XZxaWFtrbrePv2yBYmJcwjM5P/Il2aYpsCauP3BChRN0w0DANN00Tg3BeCnunb0NCgqq9vUHNLyse3Vu5A27Yf6HpiCqOtYZg8RCwutPSPsTVXccPBmMo4gNKyMJ1Oo+s6aNsWOo6NyWQSU6mUunjxklq+4nUsnlP2uKkpgNns0MnR0fvMX0GdcWPGYFL8/Wuon8bL2368HW9nU+rS5Svqm2/3IXmso6MTYzfa1cVLl9Xu3XtU+YKlOPOVOWPV1ccxnU7dbQ4GJgvBmT/QlgSamArqV3uPjvGEMZZ0XbWzahcdUouXrFArVr6hXlu2SpXOX0TfxktKFz4+Wn0MHcf5xzSN8mTSJXKTmKYZNMtFUvig620JDy0pMd7fj6tWv1Ugj82ZW+bNKi71ZhfP9xYuWlao3FaFjY0BnxmBpdMp5jhOkZQW8zdtWnImBRTZElhfwpzKAeoA+KOWlmt4+nQNkmkPHz6CtWfOYiTSipZlPchlsyebmwOTiRmBua7r68FsW7CzZ68zWo5UPvWBc2C2LaeNjAxXDefz52gCcrlscHg4X0PWIDVJAMqlMokZgdH2/xceF02SwJ+/igAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "TuringTrader.com, Bonds-NOT: A bond strategy on steroids",
                "title": "TuringTrader.com, Bonds-NOT: A bond strategy on steroids",
                "src": "/static/2b23f891a5d398e021f35a8c1073fc00/01e7c/logo-512x512.png",
                "srcSet": ["/static/2b23f891a5d398e021f35a8c1073fc00/5a46d/logo-512x512.png 300w", "/static/2b23f891a5d398e021f35a8c1073fc00/01e7c/logo-512x512.png 512w"],
                "sizes": "(max-width: 512px) 100vw, 512px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p><strong parentName="p">{` Learn More `}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/li-universal-investment-strategy/"
            }}>{`Universal Investment Strategy`}</a></li>
        </ul>
      </Aside>
      <p>{`The operation of `}<em parentName="p">{`Bonds-NOT`}</em>{` can be summarized as follows:`}</p>
      <ul>
        <li parentName="ul">{`create two independent baskets, each managed by the `}<em parentName="li">{`Universal Investment Strategy`}</em>
          <ul parentName="li">
            <li parentName="ul">{`basket #1: U.S. Treasuries with shorter maturities and investment-grade corporate bonds`}</li>
            <li parentName="ul">{`basket #2: long-term U.S. Treasuries, U.S. Stocks, Gold, and high-yield bonds`}</li>
          </ul>
        </li>
        <li parentName="ul">{`allocate capital between the two baskets with the `}<em parentName="li">{`Universal Investment Strategy`}</em></li>
        <li parentName="ul">{`rebalance monthly`}</li>
      </ul>
      <p>{`The `}<em parentName="p">{`Universal Investment Strategy`}</em>{` performs an iterative mean-variance optimization of the managed assets. While being CPU-intensive, this method does not require an estimation of future returns or covariances, making it remarkably robust. The two independently managed baskets allow `}<em parentName="p">{`Bonds-NOT`}</em>{` to take advantage of negative correlations while limiting the tendency to favor low-volatility assets with poor returns over better-performing investments. Each of the managed baskets has docile characteristics with low volatility, which allows combining them in a tiered approach as a final step. With this 2-tiered approach, `}<em parentName="p">{`Bonds-NOT`}</em>{` is technically a meta-strategy, much like our `}<a parentName="p" {...{
          "href": "/articles/all-stars-portfolios/"
        }}>{`All-Stars Portfolios`}</a>{`.`}</p>
      <h2>{`Diversification`}</h2>
      <p><em parentName="p">{`Bonds-NOT`}</em>{` is primarily a bond strategy. However, unlike investing in the aggregate bond market, the composition of the portfolio is not static. Instead, the strategy continuously selects the best performing bonds of varying maturities and credit ratings. To further boost returns, `}<em parentName="p">{`Bonds-NOT`}</em>{` may add exposure to U.S. stocks when conditions to do so are favorable.`}</p>
      <p>{`Because `}<em parentName="p">{`Bonds-NOT`}</em>{` invests in broad indices, it is well diversified for single-company risks. But thanks to its dynamic management, `}<em parentName="p">{`Bonds-NOT`}</em>{` is better diversified across asset classes than holding a proxy of the aggregate bond market. Similar to the bond market, the strategy's beta remains close to zero.`}</p>
      <h2>{`Returns & Volatility`}</h2>
      <p>{`In most years, `}<em parentName="p">{`Bonds-NOT`}</em>{` beats its benchmark, the aggregate bond market, including those with low bond returns. The portfolio's standard deviation of returns slightly higher than its benchmark, but other risk measures, including maximum drawdown, Ulcer Index, and maximum flat days, are lower. The risk-adjusted returns, notably the Sharpe Ratio and the Ulcer Performance Index, clearly favor `}<em parentName="p">{`Bonds-NOT`}</em>{` over the aggregate bond market.`}</p>
      <p>{`The Monte-Carlo simulation confirms these observations. `}<em parentName="p">{`Bonds-NOT`}</em>{` offers a massive upside over the aggregate bond market while at the same time having a significantly lower risk profile.`}</p>
      <h2>{`Account & Tax Considerations`}</h2>
      <p><em parentName="p">{`Bonds-NOT`}</em>{` trades frequently and regularly triggers taxable events. Investors should not assume that the strategy holds its assets long enough to qualify for long-term treatment of capital gains. `}</p>
      <p>{`However, it is essential to see this in perspective to holding an ETF representing the aggregate bond market. Over the past three decades, bond yields were declining. This decline resulted in bonds not only paying interest but also accruing capital gains. By holding bonds for sufficiently long periods, investors could defer paying taxes on these capital gains and qualify for long-term treatment of these gains. In 2020 and moving forward, this situation is most likely different. Interest rates have reached historic lows, and with rates staying flat or even increasing, the capital gains of bonds will no longer be a relevant factor. Under these conditions, `}<em parentName="p">{`Bonds-NOT`}</em>{` has a very similar tax burden to holding bond ETFs.`}</p>
      <p><em parentName="p">{`Bonds-NOT`}</em>{` holds up to six ETFs at a time. To allow for proper position sizing, investors should allocate no less than $10,000 to the strategy.`}</p>
    </Review>
    <Notes mdxType="Notes">
      <p><strong parentName="p">{`Portfolio Revisions`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "./?v=1"
          }}>{`v1, October 2020`}</a>{`: Initial release.`}</li>
      </ul>
    </Notes>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      